.code-container { 
    font-size: 16px;
    padding: 30px 10px 40px;
    text-align: center;
}

.m-intro-container .motto-banner h3 {
    margin-top: 20px;
    font-size: 20px;
    text-align: justify;
    text-align-last: center;
    width: 60%;
    margin-left: 20%;
    font-weight: 425;
    padding: 0 30px 0;
  }

.header {
    width: 100%;
    /* border-bottom: 1px solid gray; */
    font-size: 18px;
    text-align: center;
    padding: 5px;
}
.close {
    cursor: pointer;
    position: absolute;
    display: block;
    /* padding: 2px 5px; */
    /* line-height: 20px; */
    right: 5px;
    top: 5px;
    font-size: 24px;
    /* background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece; */
}

.side-nav { 
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    padding-top: 20px;
    /* padding-top: 20px; */
    padding-right: 45px;
    float: right;
    width: 25%; 
    /* height: 70vh; */
}

.side-nav > div { 
    flex: 1;
    /* flex-grow: 1; */
    padding: 0px;
    /* height: 100px; */
    /* width: 100%; */
}

.module {
    background-color: white;
    width: 100%;
    min-width: 100px;
    height: 100%;
    padding: 25px 0 25px;
    /* padding: 32.7px; */
    border-color: #1a254c;
    /* font-size: 16px; */
    font-size: 1.1vw;
    color: #1a254c;
    /* overflow-wrap: break-word; */
}

.module:hover {
    font-weight: bold;
}

.module-active {
    width: 100%;
    min-width: 100px;
    height: 100%;
    padding: 25px 0 25px;
    border-color: #1a254c;
    font-size: 1.1vw;
    background-color: #aec6c2;
    /* font-weight: bold; */
    color: #1a254c;
    /* overflow-wrap: break-word; */
}

.module-active:focus {
    outline: none;
    text-decoration: none;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.module-video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin-top: 15px;
    padding-left: 45px;
    padding-top: 5px;
}

.resources-button {
    /* width: 75%; */
    text-align: center;
    /* clear: left; */
    padding: 20px;
    /* margin-top: 10px; */
    /* font-size: 14px; */
    font-size: 1.1vw;
}
.self-care-button {
    /* width: 75%; */
    text-align: center;
    /* clear: left; */
    padding: 20px;
    /* margin-top: 10px; */
    /* font-size: 18px; */
    font-size: 1.1vw;
}

.button-1 {
    background-color: #1a254c;
    /* font-size: 16px; */
    color: #ebf2ff;
    transition-duration: 0.2s;
    border: none;
    /* margin: */
    /* padding: 10px; */
    min-height: 8.5vh;
    min-width: 135px;
    /* padding-left: 25px;
    padding-right: 25px; */
    /* margin-right: 10px; */
}

.button-1:hover {
    background-color: #7383a8;
    color: #1a254c;
}

.button-1:focus {
    outline: none;
    text-decoration: none;
}

.button-2-container {
    position: absolute;
    /* margin-top: 210px;
    z-index: 10; */
    text-align: center;
    font-size: 25px;
    color: white;
}

.button-2 {
    font-size: 50px;
    color: white;
    background-color: black;
    border: none;
    /* position: absolute; */
    /* top: 50%;
    width: 100%; */
    /* margin-top: 225px;
    margin-left: -550px;
    z-index: 10;
    text-align: center; */
}

.button-2:focus {
    outline: none;
    text-decoration: none;
}

/* @media only screen and (max-width: 1200px) {
    .self-care-button {
        font-size: 15px
    }
} */
@media only screen and (max-width: 1070px) {
    .module, .module-active {
        padding: 18px 0 18px;
    }
}
@media only screen and (max-width: 815px) {
    .module, .module-active {
        padding: 15px 0 15px;
    }
    .button-1 {
        /* font-size: 1.7vw; */
        min-height: 7vh;
        min-width: 100px;
    }
}
@media only screen and (max-width: 767px) {
    .resources-button {
        padding: 20px 20px 10px;
    }
    .self-care-button {
        padding: 10px 20px 20px;
    }
}

@media only screen and (max-width: 700px) {
    .side-nav {
        padding-right: 20px;
    }
    .module-video {
        padding-left: 20px;
    }
    .module, .module-active {
        padding: 10px 0 10px;
    } 
    .button-1 {
        min-height: 6vh;
        min-width: 100px;
        font-size: 1.4vw;
    }
} 

.code-container .button-1 {
    margin: 5px;
}