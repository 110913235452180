.r-container {
    /*padding: 0 0 30px; */
    text-align: center;
    min-height: 66.5vh;
}

.r-container .rtitle {
   /* border-bottom: 4px solid #aec6c2; */
    /* border: 4px solid #c9dbff; */
    background-color: #1a254c;
    padding: 15px 0 20px;
    color: #aec6c2;
    /* font-weight: bold; */
    font-size: 30px;
    /* text-shadow:
        -1px -1px 0 white,
        1px -1px 0 white,
        -1px 1px 0 white,
        1px 1px 0 white; */
}

@supports (-webkit-text-stroke: 1px white) {
    .r-container .rtitle {
      -webkit-text-stroke: 1px white;
      -webkit-text-fill-color: #aec6c2;
    }
  }

.banner {
    text-align: center;
    /* color: white; */
    font-size: 16px;
    /* margin-top: -5px; */
    padding: 25px 25px 15px;
    /* background-color: #1a254c; */
}

.banner h2 {
    padding-bottom: 10px;
}

.r-container h1 {
    text-align: center;
    font-size: 25px;
    padding: 15px;
}

.r-cont-2 {
    /* background-color: white; */
    margin-left: 10%;
    width: 80%;
}

.r-nav {
    width: 100%; 
    /* margin-left: 5%; */
    /* padding-top: 10px; */
    /* text-align: center; */
    background-color: #ebf2ff;
    /* display: flex;
    justify-content: space-evenly; */
}

.r-tab {
    background-color: #ebf2ff;
    /* justify-content: space-between; */
    border: none;
    padding: 10px 37.5px 6px;
    /* width: 100%;
    height: 100%; */
    /* padding: 32.7px; */
    /* border-color: #1a254c; */
    font-size: 15px;
    color: #1a254c;
}

.r-tab:hover {
    color: #7383a8;
}

.r-tab:focus {
    outline: none;
    text-decoration: none;
}

.r-tab-active {
    /* width: 100%;
    height: 100%; */
    /* border-color: #1a254c; */
    /* justify-content: space-between; */
    font-size: 15px;
    background-color: white;
    border: none;
    padding: 10px 37.5px 6px;
    /* font-weight: bold; */
    color: #1a254c;
}

.r-tab-active:focus {
    outline: none;
    text-decoration: none;
}

.r-body {
    background-color: white;
    width: 100%;
    /* margin-left: 5%; */
    /* width: 88%;
    margin-left: 6%;
    padding: 25px 0 15px; */
}

.r-body div {
    padding: 15px 0 25px;
}

p {
    font-size: 16px;
}

/* h3 {
    font-size: 16px;
    text-decoration: underline;
} */

.r-body div {
    padding: 5px;
}

.identities {
    margin: 10px 0 0px;
    margin-left: 25%;
    /* padding-bottom: 5px; */
    cursor: pointer;
    width: 50%;
    border-bottom: 1px solid gray;
}

.asian-pacisl {
    padding-top: 15px !important;
    border-top: 1px solid gray;
}

.id_groups {
    /* margin: -15px 0 -35px; */
    padding-bottom: 0px; 
    /* border-bottom: 1px solid gray; */
}

