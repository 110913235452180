.Home {
  width: 100%;
  padding-top: 2px;
}

.proto-banner {
  margin-top: 0px;
  padding: 10px 30px 10px;
  background-color: #78153f;
  color: white;
  font-size: 18px;
  text-align: center;
  position: fixed;
}

.Home .pad {
  min-height: 70px;
}

/* @media only screen and (max-width: 1200px) {
  .Home .pad {
    min-height: 100px;
  }
} */
@media only screen and (max-width: 1104px) {
  .Home .pad {
    min-height: 100px;
  }
}
@media only screen and (max-width: 766px) {
  .Home .pad {
    min-height: 125px;
  }
}
@media only screen and (max-width: 587px) {
  .Home .pad {
    min-height: 155px;
  }
}
@media only screen and (max-width: 485px) {
  .Home .pad {
    min-height: 180px;
  }
}
@media only screen and (max-width: 443px) {
  .Home .pad {
    min-height: 205px;
  }
}

.Home .motto-banner {
    padding: 30px 20px 50px;
    text-align: center;
    background-color: #1A254C;
    /* position: -webkit-sticky; */
    /* position: sticky;
    top: 90px;
    z-index: 1; */
    /* border-style: solid;
    border-bottom-color: #aec6c2; */
  }
  
  .Home .motto-banner h1 {
    font-size: 35px;
    color: #aec6c2;
    /* text-shadow:
      -1px -1px 0 white,
      1px -1px 0 white,
      -1px 1px 0 white,
      1px 1px 0 white; */
    padding-bottom: 20px;
  }

  @supports (-webkit-text-stroke: 1px white) {
    .motto-banner h1 {
      -webkit-text-stroke: 1px white;
      -webkit-text-fill-color: #aec6c2;
    }
  }

  .Home .motto-banner h3 {
    font-size: 20px;
    /* text-align: justify; */
    text-align-last: center;
    width: 45%;
    margin-left: 27.5%;
    font-weight: 425;
    color: #ebf2ff;
    padding: 0 30px 0;
  }

  .graphic {
    text-align: center;
    padding: 15px 0 15px;
    /* height: 770px; */
    width: 100%;
    position: sticky;
    top: 150px;
    z-index: 2;
    overflow: hidden;
  }

  .graphic img {
    display: block;
    width: 100%;
    height: auto;
    /* max-width: 1432.8px;
    max-height: 1000.8px; */
    /* width: auto;
    height: auto; */
    /* margin-left: -25px;
    margin-right: -50px; */
    margin-top: -6%;
  }

  .infographic {
    text-align: center;
    padding: 40px 20px 30px;
    position: sticky;
    background-color: #ebf2ff;
    z-index: 3;
    /* width: 100%; */
    /* margin-left: 2.5%; */
  }

  .infographic img {
    width: 75%;
    height: auto;
  }

  .infographic h1 {
    font-size: 30px;
    background-color: white;
    padding: 10px 0 5px;
    margin-bottom: -3px;
    margin-left: 12.5%;
    width: 75%;
  }

  .b-container {
    text-align: center;
    padding: 15px;
    border: none;
    margin-bottom: 35px;
    /* background-color: white; */
    text-decoration: none;
    /* padding: 15px; */
  }

  .Home .b-container {
    position: sticky;
    z-index: 4;
    background-color: #ebf2ff;
  }

  .button {
    background-color: #AEC6C2;
    font-size: 15px;
    color: #1a254c;
    transition-duration: 0.2s;
    border: 1.5px solid #1A254C;
    padding: 12px;
    margin-right: 10px;
}

.Home .button {
  margin-right: 0px;
  justify-self: center;
}

.b-container .button {
  border: 2px solid #1A254C;
  padding: 15px;
}

.button:hover {
    background-color: #a66d89;
    color: #1a254c;
}

.button:focus {
    outline: none;
    text-decoration: none;
}

@media only screen and (max-width: 1000px) {
  .Home .motto-banner h3 {
    width: 90%;
    margin-left: 5%;
  }
  .infographic img {
    width: 95%;
  }
  .infographic h1 {
    width: 95%;
    margin-left: 2.5%;
  }
}