.footer-container {
    padding: 15px;
    background-color: white;
    text-align: center;
    /* width: 100%; */
}

.f-col-1 {
    /* background-color: #1a254c; */
    /* height: 100px; */
    min-width: 290px;
    max-height: 125px;
}

.f-logo {
    /* text-align: center; */
    width: 13rem;
}

.f-email {
    /* color: black; */
    /* float: left; */
    /* text-align: right; */
    font-size: 18px;
    padding: 50px 10px 0;
    min-width: 250px;
    max-height: 125px;
    /* margin-top: -35px;
    margin-left: 10%; */
}

.f-media {
    min-width: 200px;
    padding: 30px 10px 0;
    max-height: 125px;
    /* text-align: right;
    margin-top: -100px;
    margin-right: 10%; */
}

@media only screen and (max-width: 500px) {
    .f-media {
        padding: 0px;
        max-height: 75px;
        margin-bottom: 10px;
    }
    .f-email {
        padding: 0px;
        max-height: 50px;
        margin-top: 20px;
    }
}

.f-icon {
    width: 45px;
    margin: 10px;
    /* margin: 4%; */
    /* padding: 15px; */
}

/* .row {
    text-align: center;
    height: 40px;
} */

.tsp-container {
    margin-left: 12.5%;
}

.f-terms {
    font-size: 1.2rem;
    /* text-align: right; */
    margin-top: 10px;
}

.f-privacy {
    font-size: 1.2rem;
    /* text-align: center; */
    /* margin-right: 2px; */
    margin-top: 10px;
}