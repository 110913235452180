.cont {
    padding-top: 20px;
    /* position: absolute;
    left: 10%;
    width: 80%; */
    text-align: center;
    /* background-color: #ebf2ff; */
}

.cont p {
    text-align: justify;
    text-align-last: center;
    /* word-wrap: break-word; */
    /* word-break: break-all; */
}

.blurb {
    padding: 20px 70px 20px;
    text-align: center;
}

.blurb p {
    font-size: 16px;
}

.checkbox {
    font-size: 14px;
}

.center-button {
    padding: 20px;
    /* padding-top: 330px; */
    text-align: center;
}

.center-button button {
    background-color: #AEC6C2;
    font-size: 15px;
    /* font-weight: bold; */
    color: #1a254c;
    transition-duration: 0.2s;
    border: none;
    padding: 12px;
    margin-right: 10px;
}

.center-button button:hover {
    background-color: #9DB8B4;
    color: #1a254c;
}

button:disabled,
button[disabled] {
    cursor: no-drop;
    background-color: #cccccc;
    color: #666666;
}

button:disabled:hover {
    background-color: #cccccc;
    color: #666666;
}


button:focus {
    outline: none;
    text-decoration: none;
}