.a-container {
    /* padding-top: 85px; */
    text-align: center;
    min-height: 66.5vh;
    /* position: relative; */
}

.a-container h1 {
    /* border-bottom: 4px solid #aec6c2; */
    /* border: 4px solid #c9dbff; */
    background-color: #1a254c;
    padding: 15px 0 20px;
    color: #aec6c2;
    /* font-weight: bold; */
    font-size: 30px;
    /* text-shadow:
        -1px -1px 0 white,
        1px -1px 0 white,
        -1px 1px 0 white,
        1px 1px 0 white; */
}

@supports (-webkit-text-stroke: 1px white) {
    .a-container h1 {
      -webkit-text-stroke: 1px white;
      -webkit-text-fill-color: #aec6c2;
    }
  }

.a-container .a-row .row {
    padding: 0 !important; 
    margin: 0 !important;
}

.mission {
    margin-top: 15px;
    padding-bottom: 20px;
    /* padding: 0 35px 20px; */
    /* background-color: #1a254c; */
    /* color: white; */
    /* border-bottom: 4px ridge #aec6c2; */
}

.mission div p {
    text-align: justify;
    text-align-last: center;
}

.team {
    height: auto;
    /* padding-bottom: 400px; */
    /* overflow: hidden; */
}

/* .team .row div {
    padding: 0 50px 0;
} */


.team p {
    padding-top: 10px;
}

.team a {
    color: black;
}

.team a:hover {
    color: #7383a8; 
}

.team img {
    max-width: 250px;
    /* min-width: 200px; */
    /* min-width: 300px; */
    /* max-height: 300px; */
}

/* .board {
    /* padding-bottom: 250px; */
    /* border-bottom: 4px ridge #aec6c2; */
    /* background-color: #1a254c; */
    /* color: white; 
} */

.img {
    height: auto;
    width: 250px;
    padding-top: 15px;
}

/* .ttle {
    width: 250px;
} */

.a-container .tool-graphic {
    padding: 20px 0 40px;
}

.a-container .tool-graphic img {
    padding-top: 15px;
    width: 75%;
}

@media only screen and (max-width: 1000px) {
    .a-container .tool-graphic img {
      width: 95%;
    }
  }