.stats-container {
    text-align: center;
    padding: 20px;
}

.stats-container h1 {
    font-size: 30px;
}

.stats-container .chart {
    display: flex;
    flex-direction: row;
    align-items: center;
}


/* #reported-graphic h4 {
    padding: 20px;
    margin-left: 40px;
} */

.stats {
    width: 70%;
    margin-left: 15%;
    /* margin-top: -40px; */
}

.sources {
    padding: 15px;
}

.sources h2 {
    font-size: 23px;
    text-align: center;
    padding: 10px;
}

.sources li {
    font-size: 15px;
    padding-left: 5px;
}