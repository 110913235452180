/* CSS for FAQ, donate, contact us, participating schools pages */

/* FAQ Page */
.faq-container {
    width: 90%;
    margin-left: 5%;
    text-align: left;
    /* margin-top: 15px !important; */
    /* padding-bottom: 20px; */
}

.faq-header {
    margin-top: 15px;
    padding-bottom: 20px;
}

.faq-container h1 {
    text-align: center;
    width: 100% !important;
    /* margin-top: 15px !important; */
    /* padding: 15px 0 20px; */
}

.faq-container h3 {
    padding-top: 10px;
}

.faq-container p {
    font-size: 16px;
    padding-bottom: 5px;
}

/* Participating Schools Page */
.p-container {
    padding-top: 15px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    min-height: 66.5vh;
}

.p-container h1 {
    padding-top: 20px; 
}

@media only screen and (max-width: 700px) {
    .p-container h1 {
        padding-top: 30px;
    }
}

.p-container .logos img {
    width: 300px;
    height: auto;
    padding: 15px;
}

/* Modules Intro Page */
.m-intro-container {
    text-align: center;
    min-height: 66.5vh;
}

.a-container .m-intro-row {
    width: 80% !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
    /* padding-top: 30px; */
}

.m-intro {
    background-color: white;
    /* width: 45%; */
    min-width: 300px;
    min-height: 200px;
    /* height: 300px; */
    /* float: left; */
    margin: 20px;
    padding: 15px 5px 20px;
}

.m-intro h2 {
    padding: 35px;
}

.m-intro .g-started {
    padding-bottom: 20px;
}
.m-intro button {
    background-color: #77153f;
    color: #ebf2ff;
    border: none;
}

.m-intro .button:hover {
    background-color: #a66d89;
    color: #ebf2ff;
    text-decoration: none;
}

.m-intro .to-modules {
    padding-top: 0px;
    padding-bottom: 10px;
}

@media only screen and (max-width: 1156px) {
    .m-intro .g-started {
        padding-top: 24px;
    }
}
@media only screen and (max-width: 1067px) {
    .m-intro .g-started {
        padding-top: 0px;
    }
}
@media only screen and (max-width: 993px) {
    .m-intro .g-started {
        padding-top: 24px;
    }
}
@media only screen and (max-width: 913px) {
    .m-intro .g-started {
        padding-top: 0px;
    }
}

/* Contact Us Page */
.contact-container {
    padding: 0 0 30px;
    text-align: center;
}

.contact-container h1 {
    padding: 30px 20px 25px;
}

.contact-form-container {
    background-color: white;
    width: 70%;
    height: auto;
    margin-left: 15%;
    padding: 15px 10px 25px;
    margin-bottom: 20px;
}

.contact-form-container .form-text {
    width: 40%;
    margin-left: 2%;
    padding-bottom: 0px;
    font-size: 14px;
    /* font-weight: 200;
    color: #1a254c; */
}

@media only screen and (max-width: 800px) {
    .contact-form-container {
        width: 85%;
        margin-left: 7.5%;
    }
    .contact-form-container .form-text {
        width: 70%;
    }
}

.form-text .text {
    padding-bottom: 10px;     
    width: 100%; 
    margin-left: -10px;
}

.error { 
    border: 2px solid #FF6565;
}

.page-error-message {
    color: #FF6565;
    height: 1em;
    /* margin-left: 0%; */
    font-size: 13px;
} 

.form-label {
    align-self: flex-start;
}

.contact-form-container .form-message {
    text-align: left;
    font-size: 14px;
    width: 96%;
    height: 300px;
    margin-left: 2%;
    padding: 5px 0 35px;
    /* overflow: visible;
    white-space: normal; */
    /* max-width: 500px;
    overflow-wrap: break-word; */
    /* padding: 10px 0px 20px; */
    /* padding-left: 300px; */
    /* float: left; */
}

.form-message .message {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    height: 100%;
    padding: 4px 7.5px 10px;
    width: 100%;
    border: 1.5px solid #ced4da;
    /* overflow: visible; */
    /* max-width: 500px; */
    /* word-wrap: break-word; */
}

.form-message .message:focus {
    border: 1px solid #82beff;
    outline: 2px solid #cbe0ff;
}

.anon-form-container {
    width: 70%;
    height: auto;
    margin-left: 15%;
    padding: 15px 10px 25px;
    margin-bottom: 20px;
}

.anon-form-container .anon-form {
    /* text-align: center; */
    display: flex;
    justify-content: center;
}

.anon-text {
    width: 80%;
}

.anon-text .comment {
    width: 95%;
}

.smler {
    padding: 5px;
}

@media only screen and (max-width: 1000px) {
    .anon-form-container {
        width: 90%;
        margin-left: 5%;
    }
    .anon-text {
        width: 95%;
    }
    .smler {
        margin-top: 15px;
    }
}

/* Info Form */ 
.formValues {
    display: flex; 
    /* justify-content: center; */
    justify-items: stretch;
    justify-content: center;
    align-items: center;
    /* width: 400px; */
}

.formValues h2 {
    /* text-align: right; */
    justify-self: end;
}

.formValues h3 {
    margin: 10px;
    border: 2px solid #1a254c;
    padding: 10px;
    background-color: #ebf2ff;
    /* text-align: left; */
    justify-self: start;
}


/* Donate Page */ 
.donate-banner a:hover {
    text-decoration: none;
}

.donate-banner a:focus {
    text-decoration: none;
}

.therm-cont {
    /* display: flex;  */
    /* justify-content: space-evenly; */
    align-items: center;
    text-align: left;
    margin-left: 15px;
}

/* #goal-amt {
    background-color: #77153f;
    color: white;
    border-radius: 2px;
    padding: 4px;
    position: absolute; 
    top: -10px;
    left: -35px;
    z-index: 3;
    font-size: 18px;
}

#curr-amt {
    position: absolute; 
    top: 30px;
    left: 55px;
    z-index: 2;
    font-size: 18px;
} */

@media only screen and (max-width: 900px) {
    #curr-amt {
        left: 50px;
    }
}

.donation-blurbs {
    width: 75%;
    margin-left: 13%;
    padding: 10px 20px 3px;
}

.donation-blurbs h2 {
    font-size: 20px;
    background-color: #aec6c2;
    display: table;
    color: black;
    margin: 10px 10px 7px;
}

.donation-blurbs p {
    margin-left: 25px;
    font-size: 17px;
}

.donation-blurbs a:hover {
    color: black;
    text-decoration: none;
}

.donation-blurbs a:focus {
    color: black;
    outline: none;
    text-decoration: none;
}

.donate-banner {
    padding: 20px;
    margin-bottom: 15px;
    text-align: center;
    background-color: #1A254C;
  }
  
.donate-banner h1 {
    font-size: 35px;
    color: #aec6c2;
    padding-bottom: 10px;
  }

  @supports (-webkit-text-stroke: 1px white) {
    .donate-banner h1 {
      -webkit-text-stroke: 1px white;
      -webkit-text-fill-color: #aec6c2;
    }
  }

  .donate-banner p {
    font-size: 18px;
    text-align-last: center;
    width: 75%;
    margin-left: 12.5%;
    font-weight: 425;
    color: #ebf2ff;
    /* padding: 0 30px 0; */
  }

/* Opt Out Options Page */ 
.optout-container {
    padding-top: 15px;
    text-align: center;
    /* margin-left: 10px;
    margin-right: 10px; */
    min-height: 66.5vh;
}

.optout-container h1 {
    background-color: #1a254c;
    padding: 15px 0 20px;
    color: #aec6c2;
    font-size: 30px;
}

@supports (-webkit-text-stroke: 1px white) {
    .optout-container h1 {
      -webkit-text-stroke: 1px white;
      -webkit-text-fill-color: #aec6c2;
    }
  }
  
.optout-container h3 {
    font-weight: normal;
    margin: 20px 30px 40px;
}

.optout-container p {
    margin: 0px 100px 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid gray;
}

.optout-container .options {
    padding: 15px 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.options .convoid {
    max-width: 11%;
    min-width: 160px;
    margin-left: 44.5%;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
}

.convoid .c-error {
    margin-top: -20px;
}

.optout-container h2 {
    margin-top: 5px;
}

.optout-container .button-1 {
    font-size: 16px;
    margin: 15px 15px;
    margin-left: 0px
}

.optout-container .buttondiv {
    margin-top: -30px;
    margin-bottom: 10px;
}

/* Terms and Privacy */
/* Terms Cards */
.terms-card {
    /* padding-top: 90px; */
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
  }
  .terms-card .card-header {
    color: #7383a8;
  }
  .terms-card .card-body {
    padding: 3rem 5rem 4rem;
  }
  .terms-card .card-body h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .terms-card .card-body .indent {
    margin-left: 1.8rem;
  }
  .terms-card .card-body a {
    display: block;
    margin-top: 0.25rem;
    color: #7383a8;
  }
  .terms-card .card-body a:hover {
    color: #c9dbff;
  }
  .terms-card .card-body p {
    font-size: 0.9rem;
  }
  .terms-card .card-body li {
    margin-top: 0.4rem;
  }
  .terms-card h2 {
    margin-bottom: 0;
  }
  
  .terms-modal .card-body {
    padding: 1rem;
  }
  
  @media (max-width: 767.98px) {
    .terms-card .card-body {
      padding: 2rem 1rem;
    }
  }

.terms-card h3, h5{ 
    position:relative; 
}
.terms-card h3 span{ 
    position:absolute; 
    top:-95px;
}
.terms-card h5 span{ 
    position:absolute; 
    top:-95px;
}