.code-container { 
    font-size: 16px;
    padding: 30px 10px 40px;
    text-align: center;
}

.header {
    width: 100%;
    /* border-bottom: 1px solid gray; */
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.close {
    cursor: pointer;
    position: absolute;
    display: block;
    /* padding: 2px 5px; */
    /* line-height: 20px; */
    right: 5px;
    top: 5px;
    font-size: 24px;
    /* background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece; */
}

.close:focus {
    outline: none;
    text-decoration: none;
}

.form-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .form-container .form-row {
    display: table-row;
} */

/* .form-container .cell {
    display: table-cell;
    width: 30%;
    padding: 10px;
} */

/* .label {
    color: #1a254c;
    font-size: 1.5em;
    font-weight: 400;
} */

.form-text {
    text-align: center;
    width: 200px;
    padding: 10px 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-left: 300px; */
    /* float: left; */
}

.text {
    /* align-items: center; */
    padding: 7px 5px 5px;
}

.error { 
    border: 2px solid #FF6565;
}

.error-message {
    color: #FF6565;
    height: 1em;
    width: 300px;
    padding-left: none;
    /* text-align: center; */
    /* position: absolute; */
    font-size: 13px;
}

.convo-button-container {
    text-align: center;
    margin-left: -15px;
}

.convo-button {
    background: #1a254c;
    border: none;
    font-size: 16px;
    font-weight: 400; 
    margin: 0 10px 0;
}

.convo-button:hover {
    background: #7383a8;
}

.convo-button:disabled {
    background: #7383a8;
}
.convo-button:disabled:hover {
    background: #7383a8;
    color: white;
}
/* 
.continue {
    background: #7383a8;
    border: none;
    font-size: 16px;
    font-weight: 400; 
    padding-left: 10px;
    margin-left: 5px;
    margin-right: 5px;
}
.continue :hover {
    background: #c9dbff;
} */