.navbar-container {
    height: 90px;
}

.bg-navbar {
    background-color: white;
    width: 100%;
    font-size: 15px;
    border-bottom-color: #1A254C;
    border-style: solid;
    border-radius: 0px;
}

/* .navbar {
    min-height: 80px;
} */
  
.navbar-brand {
    padding: 5px 15px;
    height: 80px;
    line-height: 80px;
}

.navbar-brand>img {
    height: 56px;
    margin-top: 8px;
}
  
.navbar-toggle {
    /* (80px - button height 34px) / 2 = 23px */
    margin-top: 23px;
    padding: 9px 10px !important;
}

.logo {
    /* width: 165px; */
    height: 90px;
    width: auto;
}

#dropdown {
    color: #1a254c;
    border: none;
    text-decoration: none;
    padding: 20px;
    
}

#dropdown:hover {
    color: darkgray;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-item {
    font-size: 18px;
    margin-left: 0px;
    color: #1a254c;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.donate-container.nav-item { 
    font-size: 18px;
    color: #1a254c;
    text-align: center;
}
.dropdown.nav-item { 
    font-size: 18px;
    font-weight: 500;
    color: #1a254c;
    text-align: center;
    display: flex;
    justify-content: center;
}

.collapse {
    background-color: white;
    text-align: center;
}
.collapsing {
    background-color: white;
    text-align: center;
}
/* .dropdown-menu:active {
    background-color: darkgray;
} */

.donate {
    margin-top: 19px;
    font-size: 18px;
    font-weight: 500;
    color: #1a254c;
    text-decoration: none;
}

.donate-container {
    border: none;
    background-color: white;
    text-decoration: none;
    padding: 20px;
}

.donate:focus {
    color:  #1a254c;
    text-decoration: none;
}

.donate:hover {
    color: darkgray;
    text-decoration: none;
}


.donate-container:focus {
    outline: none;
    text-decoration: none;
}

.rounded-button {
    background-color: #77153f;
    font-size: 18px;
    font-weight: bold;
    /* margin-left: -10px; */
    color: #ebf2ff;
    transition-duration: 0.2s;
    border: none;
    padding: 13px;
    /* width: 107px; */
    white-space: nowrap;
}

.rounded-button:hover {
    background-color: #a66d89;
    color: #ebf2ff;
    text-decoration: none;
}

.rounded-button:focus {
    color: #ebf2ff;
    outline: none;
    text-decoration: none;
}

.getstarted-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
